<template>
    <div class="page-media">
        <main-banner
            page-position="图文制作"
            page-title="用设计的力量，去支援疫情"
            router-name="前往墙面定制"
            router-path="/metope">
            <!-- 搜索框 -->
            <template #search>
                <search-component
                    :search-value="search"
                    :selected-value="select_type"
                    :select-options="filter_list"
                    @handleSearch="arg => handleFun(arg.search,arg.type,select_style)">
                    <!-- 快捷选项 -->
                    <template #searchFast>
                        <ul class="search-fast">
                            <li>
                                大家都在搜：
                            </li>
                            <li v-for="item in hotWords"
                                :key="item.id">
                                <a href="javascript:;"
                                    @click="handleFast(item)">
                                    {{item}}
                                </a>
                            </li>
                        </ul>
                    </template>
                </search-component>
            </template>
        </main-banner>
        <div class="container page-media-content">
            <!-- filter -->
            <filter-list
                :data-list="filter_list">
            </filter-list>
            <!-- 热门 -->
            <main-hot
                hot-title="热门模板"
                list-router="/media/list"
                :goods-list="goods_list">
                <template #goodsItem="{ item }">
                    <goods-item
                        router-path="mediaDetail"
                        :goods-item="item">
                    </goods-item>
                </template>
            </main-hot>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            let search = sessionStorage.getItem('search') || '',
                select_type = sessionStorage.getItem('type') || '0',
                select_style = sessionStorage.getItem('style') || '0';
            return {
                search,
                select_type,
                select_style,
                
                hotWords: [ "病毒", "口罩", "武汉加油", "新年快乐", "情人节", "元宵" ],
                filter_list: [
                    {
                        value: '1',
                        label: "拉页"
                    },
                    {
                        value: '2',
                        label: "相册"
                    },
                    {
                        value: '3',
                        label: "台历"
                    },
                    {
                        value: '4',
                        label: "名片"
                    },
                    {
                        value: '5',
                        label: "宣传单"
                    },
                    {
                        value: '6',
                        label: "明信片"
                    }
                ],
                goods_list: [
                    {
                        id: "112787920409342126",
                        img: "http://www.ijustsharing.com/jx/static/wall/skin/DW0000003.jpg",
                        name: "DW0000003",
                        owner_avatar: "http://www.ijustsharing.com:80/jx/static/upload/1575850766316.jpg",
                        owner_gender: 1,
                        owner_name: "Mei：）",
                        release: "2020-07-10",
                        sell: 97
                    },{
                        id: "112787920409342126",
                        img: "http://www.ijustsharing.com/jx/static/wall/skin/DW0000003.jpg",
                        name: "DW0000003",
                        owner_avatar: "http://www.ijustsharing.com:80/jx/static/upload/1575850766316.jpg",
                        owner_gender: 1,
                        owner_name: "Mei：）",
                        release: "2020-07-10",
                        sell: 97
                    },{
                        id: "112787920409342126",
                        img: "http://www.ijustsharing.com/jx/static/wall/skin/DW0000003.jpg",
                        name: "DW0000003",
                        owner_avatar: "http://www.ijustsharing.com:80/jx/static/upload/1575850766316.jpg",
                        owner_gender: 1,
                        owner_name: "Mei：）",
                        release: "2020-07-10",
                        sell: 97
                    },{
                        id: "112787920409342126",
                        img: "http://www.ijustsharing.com/jx/static/wall/skin/DW0000003.jpg",
                        name: "DW0000003",
                        owner_avatar: "http://www.ijustsharing.com:80/jx/static/upload/1575850766316.jpg",
                        owner_gender: 1,
                        owner_name: "Mei：）",
                        release: "2020-07-10",
                        sell: 97
                    },{
                        id: "112787920409342126",
                        img: "http://www.ijustsharing.com/jx/static/wall/skin/DW0000003.jpg",
                        name: "DW0000003",
                        owner_avatar: "http://www.ijustsharing.com:80/jx/static/upload/1575850766316.jpg",
                        owner_gender: 1,
                        owner_name: "Mei：）",
                        release: "2020-07-10",
                        sell: 97
                    }
                ]
            }
        },
        components: {
            searchComponent: () => import("@/components/imageMall/searchComponent"),
            mainBanner: () => import("@/components/makingMall/mainBanner"),
            filterList: () => import("@/components/makingMall/filterList"),
            mainHot: () => import("@/components/imageMall/mainHot"),
            goodsItem: () => import("@/components/makingMall/goodsItem")
        },
        methods: {
            handleFun(search, type, style){
                sessionStorage.setItem('search', search);
                sessionStorage.setItem('type', type);
                sessionStorage.setItem('style', style);
                this.search = search;
                this.select_type = type;
                this.select_style = style;
                // this.getMetopeFilters(type);
                // this.getPageMetope({
                //     search,
                //     type,
                //     style
                // })
            },
            handleFast(val){ // 快捷搜索处理
                sessionStorage.setItem('search', val);
                this.search = val;
            }
        },
    }
</script>

<style scoped>
.page-media{
    --color: #1f3eaa;
    margin-bottom: 50px;
}
.page-media-content{
    padding-top: 50px;
}
.page-media-filter{
    margin-top: 20px;
}
/* search */
.search-fast{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.search-fast > li + li{
    margin-left: 10px;
}
.search-fast > li,
.search-fast a{
    color: #fff;
}
.search-fast a:hover{
    color: #8d9afc;
}
</style>